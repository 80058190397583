.HeaderStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background-color: #99aab5;
    color: whitesmoke;
}
.LogoStyle{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;    
    font-size: 35px;
    margin: auto 0 auto 2%;
}
.ContactsBlock{
    display: flex;
    flex-direction: column;
    width: 260px;
    margin-right: 2%;
    justify-content: space-evenly;
}
.PhoneBlockStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 220px;   
}
.EmailBlockStyle{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 260px;
}
.ImgBlock{
    width: 25px;
    height: 25px;
}
.ImgStyle{
    width: 100%;
}
.NumStyle{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;    
    font-size: 20px;
}
.MailStyle{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;    
    font-size: 20px;
}

@media (max-width: 560px){
    .LogoStyle{
        font-size: 32px;
    }
    .ContactsBlock{
        width: 240px;
    }
    .PhoneBlockStyle{
        width: 200px;
    }
    .EmailBlockStyle{
        width: 240px;
    }
    .ImgBlock{
        width: 23px;
        height: 23px;
    }
    .NumStyle{
        font-size: 18px;
    }
    .MailStyle{
        font-size: 17px;
    }
}
@media (max-width: 540px){
    .LogoStyle{
        font-size: 30px;
    }
    .ContactsBlock{
        width: 230px;
    }
    .PhoneBlockStyle{
        width: 190px;
    }
    .EmailBlockStyle{
        width: 230px;
    }
}
@media (max-width: 410px){
    .LogoStyle{
        font-size: 27px;
    }
    .ImgBlock{
        width: 18px;
        height: 18px;
    }
    .NumStyle{
        font-size: 17px;
    }
    .MailStyle{
        font-size: 16px;
    }
}
@media (max-width: 390px){
    .HeaderStyle{
        height: 50px;
    }
    .LogoStyle{
        font-size: 25px;
    }
    .ImgBlock{
        width: 20px;
        height: 20px;
    }
    .NumStyle{
        font-size: 18px;
    }
    .MailStyle{
        font-size: 18px;
    }
}
@media (max-width: 390px){
    .HeaderStyle{
        height: 40px;
    }
    .LogoStyle{
        font-size: 21px;
    }
    .ContactsBlock{
        width: 190px;
    }
    .PhoneBlockStyle{
        width: 170px;
    }
    .EmailBlockStyle{
        width: 190px;
    }
    .ImgBlock{
        width: 15px;
        height: 15px;
    }
    .NumStyle{
        font-size: 16px;
    }
    .MailStyle{
        font-size: 15px;
    }
}