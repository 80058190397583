.CategoryHeader{
    display: flex;
    justify-content: space-around;
    height: 35px;
    background-color: #99aab5;
    color: whitesmoke;
    font-size: 25px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;    
}

@media (max-width: 560px){
    .CategoryHeader{
        font-size: 24px;
    }
}
@media (max-width: 410px){
    .CategoryHeader{
        font-size: 23px;
    }
}
@media (max-width: 375px){
    .CategoryHeader{
        font-size: 22px;
    }
}
@media (max-width: 320px){
    .CategoryHeader{
        font-size: 20px;
    }
}