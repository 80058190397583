.ImagePanelStyle{
    display: flex;
    width: 100%;
    height: 100%;
}
.ImgStyle{
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;
}
.DescBlockColor{
    display: flex;
    flex-direction: column;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.DescStyle{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;    
    font-size: 20px;
    margin: 1% 0 2% 1%;
    margin-left: 2.5%; 
}

