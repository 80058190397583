.MainContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}
.AppearElement-appear {
    opacity: 0.01;
}

.AppearElement-appear.AppearElement-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}


@media (max-width: 768px){
    .MainContainer{
        width: 80%;
    }
}
@media (max-width: 500px){
    .MainContainer{
        width: 100%;
    }
}