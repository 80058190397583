.ImageBlockContainer{
    color: whitesmoke;
}
.ImageBlockStyle{
    display: flex;
    width: 100%;
}
.ImageBlockStyle :hover{
    opacity: 0.9;
}
.ImageStyle{
    width: 100%;
    height: 100%;
}
.TextBlockColor{
    width: 100%;
}
.TextBlock{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 2.5%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.NameStyle{
    width: 100%;
    font-weight: bold;
    margin: 1% 0 1% 0;
}
.DescriptionStyle{
    width: 100%;
}
.NavigationBlock{
    display: flex;
    width: 100%;
    margin: 1% 0 2% 0;
    align-content: flex-start;
}
.NavigationBlock :hover{
    background-color: white;
}
.NavigationButton{
    display: flex;
    width: 130px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    background-color: lightgray;
    text-decoration: none;
}
.TextStyle{
    margin: auto;
    color: black;
}

@media (max-width: 375px){
    .NameStyle{
        margin: 2% 0 2% 0;
    }
    .NavigationBlock{
        margin: 2% 0 3% 0;
    }
}
@media (max-width: 320px){
    .NameStyle{
        margin: 3% 0 3% 0;
    }
    .NavigationBlock{
        margin: 3% 0 4% 0;
    }
}